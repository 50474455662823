import ReportActionTypes from "./actionTypes";

export function weatherHistoryReportRequest(params) {
  return {
    type: ReportActionTypes.WEATHER_HISTORY_REPORT_REQUEST,
    params,
  };
}

export function watchCountReportRequest(params) {
  return {
    type: ReportActionTypes.WATCH_COUNT_REPORT_REQUEST,
    params,
  };
}

export function userInstallationReportRequest(params) {
  return {
    type: ReportActionTypes.USER_INSTALLATION_REPORT_REQUEST,
    params,
  };
}
