const ReportActionTypes = {
  WEATHER_HISTORY_REPORT_REQUEST: "weather_history_report_request",
  WEATHER_HISTORY_REPORT_SUCCESS: "weather_history_report_success",
  WEATHER_HISTORY_REPORT_FAILURE: "weather_history_report_failure",

  WATCH_COUNT_REPORT_REQUEST: "watch_count_report_request",
  WATCH_COUNT_REPORT_SUCCESS: "watch_count_report_success",
  WATCH_COUNT_REPORT_FAILURE: "watch_count_report_failure",

  USER_INSTALLATION_REPORT_REQUEST: "user_installation_report_request",
  USER_INSTALLATION_REPORT_SUCCESS: "user_installation_report_success",
  USER_INSTALLATION_REPORT_FAILURE: "user_installation_report_failure",
};
export default ReportActionTypes;
