import React, { useEffect, useState } from "react";
import Header from "../../../common/header";
import Breadcrumb from "../../../common/breadcrumb";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { weatherHistoryReportRequest } from "../action";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "../../crop/view/index.css";

import Spinner from "../../../common/spinner";

import { CSVLink } from "react-csv";

import CustomButton from "../../../common/button";
import moment from "moment";

const Index = (props) => {
  let { weather_history_loading, weather_history } = props.report;
  let headers = [
    { label: "UMVA ID", key: "umva_id" },
    { label: "User Type", key: "user_type" },
    { label: "Country", key: "country" },
    { label: "Province", key: "province" },
    { label: "Commune", key: "commune" },
    { label: "Zone", key: "zone" },
    { label: "Colline", key: "colline" },
    { label: "Locality", key: "locality" },
    { label: "Group", key: "group" },
    { label: "Latitude", key: "lat" },
    { label: "Longitude", key: "lng" },
    { label: "Created At", key: "created_at" },
    { label: "device_id", key: "device_id" },
    { label: "version_number", key: "version_number" },
  ];

  const [fromData, setFromDate] = useState("");
  const [toDate, setToData] = useState("");
  const [error, setError] = useState({ from: "", to: "" });
  const [downloadLink, setDownloadLink] = useState(false);

  useEffect(() => {
    weather_history = [];
  }, []);

  useEffect(() => {
    if (Object.values(weather_history).length > 0) {
      setDownloadLink(true);
    }
  }, [weather_history_loading]);

  const handleSubmit = () => {
    setError({ from: "", to: "" });

    if (fromData === "" && toDate === "") {
      setError({
        from: "From Date is required.",
        to: "To Date is required.",
      });
    } else if (fromData === "") {
      setError({
        from: "From Date is required.",
        to: "",
      });
    } else if (toDate === "") {
      setError({
        from: "",
        to: "To Date is required.",
      });
    } else {
      let _from = new Date(fromData).getTime();
      let _to = new Date(toDate).getTime();

      if (_from > _to) {
        setError({
          from: "From Date must be greater than To Date",
          to: "",
        });
      } else {
        props.actions.weatherHistoryReportRequest({
          from: fromData,
          to: toDate,
          org_id: props.org_id,
        });
      }
    }
  };

  return (
    <div className="characteristicsContainer">
      <Header header={false} displayLanguage={true} />

      <Breadcrumb />

      <div className="generalBody" style={{ flexDirection: "column" }}>
        <ToastContainer />

        {weather_history_loading ? (
          <Spinner />
        ) : (
          <div
            className="formCard"
            style={{ flexDirection: "column", flex: 1 }}
          >
            <div className="TextBoxContainer">
              <div className="leftSelectLabelContainer1">
                <span>From</span>
              </div>

              <div
                className="rightSelectLabelContainer1"
                style={{ flexDirection: "column" }}
              >
                {Object.values(error).length > 0 && (
                  <span className="errorText">{error.from}</span>
                )}
                <input
                  type="date"
                  className="textBoxInput"
                  onChange={(e) => {
                    setError({ ...error, from: "" });
                    setFromDate(e.target.value);
                  }}
                  max={new moment().format("YYYY-MM-DD")}
                />
              </div>
            </div>

            <div className="TextBoxContainer">
              <div className="leftSelectLabelContainer1">
                <span>To</span>
              </div>

              <div
                className="rightSelectLabelContainer1"
                style={{ flexDirection: "column" }}
              >
                {Object.values(error).length > 0 && (
                  <span className="errorText">{error.to}</span>
                )}
                <input
                  type="date"
                  className="textBoxInput"
                  onChange={(e) => {
                    setError({ ...error, to: "" });
                    setToData(e.target.value);
                  }}
                  max={new moment().format("YYYY-MM-DD")}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="leftSelectLabelContainer1"></div>

              <div className="rightSelectLabelContainer1">
                {error.from === "" && error.to === "" && (
                  <CustomButton
                    name={`Download`}
                    onSaveButtonClick={handleSubmit}
                  />
                )}
                {downloadLink && (
                  <CSVLink
                    data={weather_history}
                    headers={headers}
                    filename={`weather_history_from_${fromData}_to${toDate}_for_${props.organization}`}
                    enclosingCharacter={``}
                  >
                    Download Weather History Report
                  </CSVLink>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    report: state.report,
    org_id: state.auth.uid,
    organization: state.auth.organization,
  };
};

function mapDispatchtoProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        weatherHistoryReportRequest,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchtoProps)(Index);
