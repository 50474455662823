import ReportActionTypes from "./actionTypes";
import { fork, take, call, put } from "redux-saga/effects";
import "react-toastify/dist/ReactToastify.css";
import { ReportService } from "./service";
import { toast } from "react-toastify";
import moment from "moment";

export function* weatherHistoryRequest() {
  while (true) {
    let { params } = yield take(
      ReportActionTypes.WEATHER_HISTORY_REPORT_REQUEST
    );

    var weather_history = yield call(ReportService.getWeatherHistory, params);

    weather_history = weather_history.map((item) => {
      return {
        ...item,
        ...{ created_at: moment(item.created_at).format("L") },
      };
    });

    if (weather_history) {
      if (Object.values(weather_history).length > 0) {
        if (!"error" in weather_history) {
          yield put({
            type: ReportActionTypes.WEATHER_HISTORY_REPORT_FAILURE,
          });
        } else {
          yield put({
            type: ReportActionTypes.WEATHER_HISTORY_REPORT_SUCCESS,
            weather_history: weather_history,
          });
        }
      } else {
        toast.error("No content in the report", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        yield put({
          type: ReportActionTypes.WEATHER_HISTORY_REPORT_FAILURE,
        });
      }
    } else {
      yield put({
        type: ReportActionTypes.WEATHER_HISTORY_REPORT_FAILURE,
      });
    }
  }
}

export function* watchCountRequest() {
  while (true) {
    let { params } = yield take(ReportActionTypes.WATCH_COUNT_REPORT_REQUEST);

    var watch_count = yield call(ReportService.getWatchCountReport, params);

    watch_count = watch_count.map((item) => {
      return {
        ...item,
        ...{ created_at: moment(item.created_at).format("L") },
      };
    });

    if (watch_count) {
      if (Object.values(watch_count).length > 0) {
        if (!"error" in watch_count) {
          yield put({
            type: ReportActionTypes.WATCH_COUNT_REPORT_FAILURE,
          });
        } else {
          yield put({
            type: ReportActionTypes.WATCH_COUNT_REPORT_SUCCESS,
            watch_count: watch_count,
          });
        }
      } else {
        toast.error("No content in the report", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        yield put({
          type: ReportActionTypes.WATCH_COUNT_REPORT_FAILURE,
        });
      }
    } else {
      yield put({
        type: ReportActionTypes.WEATHER_HISTORY_REPORT_FAILURE,
      });
    }
  }
}

export function* userInstallationRequest() {
  while (true) {
    let { params } = yield take(
      ReportActionTypes.USER_INSTALLATION_REPORT_REQUEST
    );

    var user_installation = yield call(
      ReportService.getUserInstallationReport,
      params
    );

    user_installation = user_installation.map((item) => {
      return {
        ...item,
        ...{ created_at: moment(item?.created_at).format("L") },
        ...{ country: item?.country_address?.country },
        ...{ province: item?.country_address?.area1 },
        ...{ commune: item?.country_address?.area2 },
        ...{ zone: item?.country_address?.area3 },
        ...{ colline: item?.country_address?.area4 },
        ...{ locality: item?.country_address?.area5 },
        ...{ group: item?.country_address?.group },
      };
    });

    if (user_installation) {
      if (Object.values(user_installation).length > 0) {
        if (!"error" in user_installation) {
          yield put({
            type: ReportActionTypes.USER_INSTALLATION_REPORT_FAILURE,
          });
        } else {
          yield put({
            type: ReportActionTypes.USER_INSTALLATION_REPORT_SUCCESS,
            user_installation: user_installation,
          });
        }
      } else {
        toast.error("No content in the report", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        yield put({
          type: ReportActionTypes.USER_INSTALLATION_REPORT_FAILURE,
        });
      }
    } else {
      yield put({
        type: ReportActionTypes.USER_INSTALLATION_REPORT_FAILURE,
      });
    }
  }
}

export default function* ReportSaga() {
  yield fork(weatherHistoryRequest);
  yield fork(watchCountRequest);
  yield fork(userInstallationRequest);
}
