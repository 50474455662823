import React from "react";
import { Route } from "react-router-dom";
import Index from "./view/index";
import WatchCountReport from "./view/watchCountReport";
import UserInstallationReport from "./view/userInstallationReport";

const Report = (props) => {
  return (
    <>
      <Route path={`${props.match.path}/weather`} component={Index} />

      <Route
        path={`${props.match.path}/video_watch_count`}
        component={WatchCountReport}
      />

      <Route
        path={`${props.match.path}/user_installation`}
        component={UserInstallationReport}
      />
    </>
  );
};

export default Report;
